.wrapper {
    background-color: #f0f0f0;
    position: relative;
    width: 60vw;
    height: 69vh;
    border-radius: 3px;
    color: rgb(37, 37, 37);
    font-size: 14px;
    border-radius: 5px;
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    gap: 20px;
    padding: 50px 0;
}
