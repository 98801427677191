.availableCommands {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-self: center;
    gap: 5px;
    width: 100%;

    & p {
        width: 95%;
    }

    & .commands_list {
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        gap: 10px;

        & .list_item {
            background-color: rgb(51, 135, 247);
            padding: 5px 10px;
            border-radius: 2px;
            margin: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);

            &:hover {
                cursor: pointer;
            }

            & p {
                width: 100%;
                white-space: nowrap;
                margin: 0;
                padding: 0;
                font-size: 15px;
                font-weight: normal;
            }
        }
    }
}
