.prompt {
  color: #ffffff;
  font-size: 50px;
  font-weight: 500;
  text-align: center;
}

.button {
  margin: 0 auto;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  width: 320px;
  height: 70px;
  border-radius: 5px;
  color: #222222;
  font-size: 30px;
  font-weight: 500;
  text-align: center;
}

.auth_buttons {
  width: 100vw;
  display: flex;
  justify-content: center;
}

.button:hover {
  background-color: #dfdfdf;
}

.button:active {
  background-color: #c7c7c7;
}

.webcam {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.btn {
  height: 300px;
  width: 300px;
  padding: 15px;
  background: linear-gradient(
    0deg,
    rgba(42, 44, 133, 1) 0%,
    rgba(26, 84, 178, 1) 0%,
    rgba(0, 151, 255, 1) 100%,
    rgba(71, 117, 139, 1) 100%
  );
  border-radius: 20px;
  margin: 20px;
  font-size: 30px;
  color: white;
  cursor: pointer;
  transition: all 0.2s ease-in;
}

.btn:hover {
  transform: translateY(-2px);
}

.footer {
  font-size: 16px;
  font-weight: 500;
  color: #dfdfdf;
  position: fixed;
  bottom: 20px;
  left: 20px;
}
