.wrapper {
    background-image: url('../../img/background.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100vh;
    z-index: -3;
    display: flex;
    justify-content: center;
    align-items: center;
}