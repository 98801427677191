.root {
    /* text-align: center; */
    color: aliceblue;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
    background: linear-gradient(
        0deg,
        rgba(42, 44, 133, 1) 0%,
        rgba(26, 84, 178, 1) 0%,
        rgba(0, 151, 255, 1) 100%,
        rgba(71, 117, 139, 1) 100%
    );
    padding: 50px 50px 20px 50px;
    border-radius: 20px;
    & h1 {
        text-align: center;
    }
    & .back {
        position: absolute;
        top: 10px;
        left: 10px;
        font-size: 20px;
        font-weight: 500;
        color: white;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    & .camera {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 15px;
        overflow: hidden;
        & .loader_container {
            position: absolute;
            top: 0;
            width: 100%;
        }
    }
    & .alert {
        width: 75%;
        height: 70px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        & div {
            transition: 0.2s opacity;
            font-size: 18px;
            height: 100%;
        }
    }
    & .buttons {
        display: flex;
        gap: 20px;
        & .button {
            background-color: rgb(51, 135, 247);
            height: 40px;
            padding: 10px 30px;
            outline: none;
            &:disabled {
                background-color: rgba(125, 181, 255, 0.726);
            }
        }
    }
}

@media (max-height: 800px) {
    .root {
        width: 600px;
        height: 600px;
        & .camera {
            height: 450px;
            width: 500px;
        }
    }
}

/* .root .camera video {
    position: absolute;
    height: 100%;
    transform: translateX(-50%) !important;
    top: 0;
    left: 50%;
}

.root .camera div {
    position: absolute;
    z-index: 1000;
} */
