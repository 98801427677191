.root {
    width: 150px;
    height: 200px;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    left: 50%;

    & img {
        width: 150px;
        position: absolute;
        transition: width 0.2s;
        bottom: 0;
        transform: translateX(-50%);
        left: 50%;
        pointer-events: none;
    }
}
