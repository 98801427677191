.wrapper {
  cursor: pointer;
  width: 24%;
  height: auto;
  min-width: 200px;
  border-radius: 20px;
  /* background: rgb(42, 44, 133); */
  background: linear-gradient(
    0deg,
    rgba(42, 44, 133, 1) 0%,
    rgba(26, 84, 178, 1) 0%,
    rgba(0, 151, 255, 1) 100%,
    rgba(71, 117, 139, 1) 100%
  );
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  transition: 0.2s;
}

.wrapper img {
  max-height: 130px;
}

.disable {
  opacity: 0.6;
}

.wrapper:hover {
  transform: translateY(-2px);
}

.wrapper:active {
  background: linear-gradient(
    0deg,
    rgb(37, 39, 131) 0%,
    rgb(18, 79, 177) 0%,
    rgb(0, 131, 218) 100%,
    rgb(53, 108, 134) 100%
  );
}

.wrapper p {
  min-height: 70px;
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 1.1;
}

@media (max-height: 890px) {
  .wrapper {
    width: 190px;
    height: 190px;
  }
  .wrapper p {
    font-size: 17px;
  }
  .wrapper img {
    width: 70px;
  }
}
