@import "../style.module.scss";

.container {
    width: 520px;
    padding: none;
    height: 100px;

    & .header {
        font-size: 24px;
        font-weight: 500;
    }

    & .edit {
        position: absolute;
        top: 10px;
        right: 50px;

        &:hover {
            cursor: pointer;
        }
    }
}
