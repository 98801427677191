.root {
    transition: 0.5s;
    position: fixed;
    background-color: #ffffff;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1000;

    animation: fadein 0.5s;

    &__exit {
        top: 30%;
        left: 42%;
        height: 59vh;
        width: 50vw;
        border-radius: 20px;
        background-color: #f0f0f0;
    }

    & .main_container {
        height: 100%;
        width: 100%;

        & .main {
            height: 100%;
            display: flex;
            flex-direction: row;

            & .menu {
                height: 100%;
                width: 400px;
                border-right: solid 1px rgba($color: #000000, $alpha: 0.2);
                box-shadow: 0 0 5px rgba($color: #000000, $alpha: 0.2);
                padding: 20px;
                display: flex;
                flex-direction: column;
                gap: 30px;

                & .button {
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                & .head {
                    width: 100%;

                    & video {
                        width: 100%;
                    }
                }
            }

            & .content {
                padding: 50px 0;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                & .view {
                    height: 80%;

                    & p {
                        font-size: 100px;
                        width: 500px;
                        text-align: center;
                    }
                }

                & .input {
                    background-color: rgb(0, 110, 255);

                    & p {
                        font-size: 30px;
                    }
                }
            }
        }
    }
}

@keyframes fadein {
    0% {
        // transform: scale(1.2);
        top: 30%;
        left: 45%;
        height: 59vh;
        width: 45vw;
        border-radius: 20px;
    }
    // 60% {
    //     transform: scale(0.9);
    // }
    // 100% {
    //     transform: scale(1);
    // }
}
