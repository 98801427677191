.data_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    // max-height: 600px;
    height: 100%;
    overflow-y: auto;
    padding: 20px 5% 0px 5% !important;

    & p {
        font-size: 32px;
    }

    @media (max-width: 1280px) {
        // max-height: 550px;
    }

    & .payload {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        gap: 50px;

        & .image_container {
            display: flex;
            justify-content: center;
            width: 100%;
            & img {
                object-fit: contain;
                height: 100%;
                width: 100%;
            }
        }

        & .video_container {
            width: 900px;
            min-height: 600px;
        }

        & .audio_container {
            width: 100%;
            padding: 20px 0;
        }

        & .answers {
            display: flex;
            flex-direction: column;
            gap: 10px;
            width: 70%;

            & .answer {
                padding: 10px 15px;
                border-color: rgba(24, 122, 250, 0.8);
                border-radius: 10px;
                border-style: solid;
                border-width: 2px;
                background-color: white;
                box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.2);
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 10px;
                position: relative;
                overflow: hidden;

                & .index {
                    position: absolute;
                    top: -3px;
                    left: 0px;
                    color: rgba(24, 122, 250, 0.6);
                    font-weight: bolder;
                    font-size: 27px;
                }

                & input {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
}
