@import "../../style.modules.scss";
@import "../style.module.scss";

.main {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    & span {
        width: 100%;
        text-align: center;
        font-size: 24px;
        font-weight: 500;
    }

    & textarea {
        padding: 10px;
        font-size: 20px;
        width: 100%;
        height: 80%;
        resize: none;
        border-radius: 5px;
        border-color: black;
        border-style: solid;
        border-width: 1px;
    }

    & .answer {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 20px;

        & .text_container {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 10px;
            align-items: flex-start;

            & span {
                text-align: left;
                font-weight: normal;
                text-indent: 20px;
                font-size: 16px;
            }
        }
    }

    & .save {
        padding: 25px 40px;
        font-size: 24px;
        background-color: #006eff;
    }
}
