.review_container {
    color: black;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.2);
    display: flex;
    flex-direction: column;
    gap: 10px;

    & .title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        padding-bottom: 10px;
        border-bottom: 1px solid rgba($color: #000000, $alpha: 0.5);

        & .author_container {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;

            & .avatar {
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                background-color: rgba($color: #000000, $alpha: 0.1);

                & svg {
                    width: 25px;
                    height: 25px;
                    color: #006eff;
                }
            }

            & .author {
                display: flex;
                flex-direction: column;
                gap: 7px;

                & .stars {
                    display: flex;
                    flex-direction: row;
                    gap: 2px;

                    & .star {
                        color: rgba($color: #000000, $alpha: 0.1);
                        fill: rgba($color: #000000, $alpha: 0.1);

                        &__selected {
                            color: rgba($color: #ffd700, $alpha: 1);
                            fill: rgba($color: #ffd700, $alpha: 1);
                        }
                    }
                }

                & .name {
                    font-weight: 500;
                }
            }
        }

        & .date {
            & span {
                font-weight: 500;
                font-size: 14px;
                color: rgba($color: #000000, $alpha: 0.5);
            }
        }
    }

    & .comment {
        padding: 5px 5px 10px 5px;
    }
}
