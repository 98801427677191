@import "../style.module.scss";

.availableCommands {
    align-items: center;
    flex-direction: column;

    & .cancel {
        margin-top: 20px !important;
        background-color: rgb(255, 57, 90);
        padding: 5px 10px;
        border-radius: 2px;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);

        & :hover {
            cursor: pointer;
        }

        & p {
            width: 100%;
            white-space: nowrap;
            margin: 0;
            padding: 0;
            font-size: 15px;
            font-weight: normal;
        }
    }
}
