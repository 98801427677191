.corusel {
    width: 900px;
    max-width: 900px;
    display: flex;
    flex-direction: column;
    height: 540px;
}

.my_swiper {
    width: 100%;
    max-width: 900px;
    height: 500px;
    position: relative;
    // background-image: url($swiper_background);
    // background-position: center;
    // background-size: cover;

    & .slide {
        max-width: 900px;
        width: 100% !important;
        height: 100%;

        & .slide_content {
            height: 100%;
            width: 100%;

            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

.pagination {
    width: 100%;
    max-width: 900px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    z-index: 200;
    margin-top: 10px;
    height: 25px;
}

.pagination_item {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: rgba($color: #006eff, $alpha: 0.2);
}

.pagination_item_selected {
    width: 25px;
    height: 25px;
    background-color: rgba($color: #006eff, $alpha: 0.2);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: pagination 0.2s cubic-bezier(0.04, 0.64, 0.17, 1.05);

    & div {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: #006eff;
    }
}

@keyframes pagination {
    0% {
        width: 15px;
        height: 15px;
        margin: 0 5px;
    }
    100% {
        width: 25px;
        height: 25px;
        margin: 0;
    }
}

.swiper_button_next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    width: 40px;
    height: 40px;
    z-index: 200;
    display: flex;
    flex-direction: row-reverse;

    & svg {
        width: 100%;
        height: 100%;
        color: #006eff;
    }
}

.swiper_button_prev {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 40px;
    height: 40px;
    z-index: 200;

    & svg {
        width: 100%;
        height: 100%;
        color: #006eff;
    }
}
