.data_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    max-height: 600px;
    overflow-y: auto;
    padding-right: 5px;

    & > p {
        width: 90%;
    }
}
