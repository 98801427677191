.container {
    // background-color: red;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    & .header {
        height: 90px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        & p {
            font-size: 24px;
            font-weight: 500;
        }
    }

    & .files_container {
        padding: 20px;
        overflow-y: auto;
        width: 100%;
        height: calc(100% - 90px);
        display: flex;
        flex-direction: column;

        gap: 15px;
        box-shadow: inset 0 5px 10px rgba($color: #000000, $alpha: 0.1);

        & .empty {
            height: 70px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 18px;
            font-weight: 500px;
        }

        & .row {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            gap: 10px;
            align-items: center;
            padding: 5px 10px;
            border-radius: 10px;
            box-shadow: 0px 0px 5px rgba($color: #000000, $alpha: 0.1);
            background-color: rgba($color: #000000, $alpha: 0.05);

            & .file_icon {
                & svg {
                    width: 50px;
                }
            }

            & .file_name {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 5px;

                & span {
                    width: 100%;
                    font-size: 20px;
                }

                & .options {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    gap: 15px;
                    & span {
                        width: auto;
                        font-size: 16px;
                    }
                }
            }

            & .download {
                & svg {
                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
