.main_wrapper {
    position: fixed;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: scroll;

    & .rutube {
        width: calc(100% - 400px) !important;
        height: 600px !important;
    }

    
}
