.container {
    border-width: 2px;
    border-radius: 4px;
    border-color: darkslategrey;
    border-style: solid;
    width: 280px;
    background-color: white;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    box-shadow: 0px 0px 10px 7px rgba($color: #000000, $alpha: 0.1);
    overflow: hidden;

    & p {
        width: 80%;
        padding: 0;
        margin: 0;
    }

    & textarea {
        padding: 5px;
        margin: 0;
        width: calc(90% - 10px);
        height: 200px;
        resize: none;
        overflow: auto;
        border-radius: 5px;
        border-width: 1px;
    }

    & input {
        padding: 5px;
        margin: 0;
        width: calc(90% - 10px);
        border-radius: 5px;
        border-width: 1px;
    }

    & img {
        border-style: solid;
        border-color: darkslategray;
        border-width: 1px;
        border-radius: 5px;
        margin: 0;
        width: 90%;
    }
}

.point_in {
    height: 15px;
    width: 15px;
    left: -7.5px;
    background-color: #3b82f6;
}

.point_out {
    height: 15px;
    width: 15px;
    right: -7.5px;
    background-color: #3b82f6;
}

.icon {
    display: block;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.skeleton {
    background: linear-gradient(-70deg, #e73c7e, #23a6d5, #23d5ab);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
    opacity: 0.7;
    // background-color: #eee;
    padding: 0;
    margin: 0;
    width: 90%;
    height: 200px;
    border-radius: 5px;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.container_demonstration {
    width: 250px;
    height: 50px;
    border-width: 2px;
    border-radius: 4px;
    border-color: darkslategrey;
    border-style: solid;
    display: flex;
    align-items: center;
    justify-content: center;

    & p {
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 1px;
    }
}
