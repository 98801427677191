.audio {
    width: calc(90% - 10px);
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    align-items: center;
}

.audio_button {
    height: 30px;
    width: 30px;
    background: linear-gradient(-70deg, #23a6d5, #23d5ab);
    background-size: 300% 300%;
    animation: gradient 10s ease infinite;
    border-radius: 50%;
    z-index: +2;
}

.audio_button .play {
    position: relative;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 55%;
}

.audio_button .pause {
    position: relative;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
}

.audio_button:hover {
    cursor: pointer;
}

.audio_title {
    margin-left: 10px !important;
}
