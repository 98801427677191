.mainBlock {
    display: flex;
    position: fixed;
    top: 0;
    right: -60%;
    width: 60%;
    z-index: 1000;
    height: 100%;
    background-color: rgb(1, 56, 70);
    transition: all 0.2s ease-in;
    
    &__active {
        right: 0;
        box-shadow: -3px 0 3px rgba(255, 255, 255, 0.616);
    }

    & .marker {
        height: 50px;
        width: 50px;
        position: absolute;
        left: -47px;
        top: 10px;

        background-color: rgb(1, 56, 70);
        box-shadow: -3px 3px 3px rgba(255, 255, 255, 0.616);
        display: flex;
        justify-content: center;
        align-items: center;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;

        & .button {
            height: 35px;
            width: 35px;
            background-color: transparent;
            border-radius: 50%;
            border: 2px solid rgb(104, 156, 252);
            text-align: center;
            color: rgb(104, 156, 252);
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    & .test_container {
        width: 100%;
        height: 100%;

        & .header {
            margin-top: 30px;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;

            & span {
                color: white;
                font-size: 24px;
            }

            & .type_select_container {
                margin-top: 15px;
                display: flex;
                align-items: center;
                flex-direction: row;
                gap: 10px;

                & span {
                    color: white;
                    font-size: 16px;
                }

                & .type_select {
                    width: 150px;
                    height: 30px;

                    & div {
                        color: white;
                        font-size: 16px;
                    }

                    & div[tabindex="0"] {
                        display: flex;
                        align-items: center;
                        justify-content: start;
                        margin-left: 5px;
                    }

                    & svg {
                        fill: white;
                    }
                }
            }
        }
    }
}
