.login_form {
  width: 700px;
  padding: 50px;
  height: 510px;
  border-radius: 3px;
  background: linear-gradient(
    0deg,
    rgba(42, 44, 133, 1) 0%,
    rgba(26, 84, 178, 1) 0%,
    rgba(0, 151, 255, 1) 100%,
    rgba(71, 117, 139, 1) 100%
  );
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  & .back {
    position: absolute;
    top: 10px;
    left: 10px;

    font-size: 20px;
    font-weight: 500;
    color: white;

    display: flex;
    flex-direction: row;
    align-items: center;
  }

  & .title {
    padding: 20px;
    color: rgb(255, 255, 255);
    font-size: 30px;
  }

  & .select {
    cursor: pointer;
    width: 600px;
    margin-top: 30px;
    height: 40px;
    font-size: 20px;
    font-weight: 400;

    & .selected {
      padding: 10px;
      width: 600px;
      height: 43px;
      font-size: large;
      margin-top: 5px;
      opacity: 0.9;
      border-radius: 5px;
      background-color: #fff;
      font-weight: 700;
      color: rgb(95, 95, 95);
    }

    & .list {
      position: relative;
      z-index: +1;
      margin-top: 1px;
      border-radius: 5px;
      background-color: #fff;
      width: 600px;
      max-height: 400px;
      overflow-y: auto;
      transition: all 0.2s ease-in;

      & .listElement {
        padding: 30px 15px;
        cursor: pointer;
        color: rgb(22, 27, 97);
        background-color: #fff;
        font-weight: 500;
        line-height: 36px;
        cursor: pointer;
      }

      & .choice {
        background-color: rgb(230, 230, 230);
      }

      &.listElement + .listElement {
        border-top: 1px solid rgb(173, 173, 173);
      }
    }
  }

  & .login_title2 {
    margin-top: 60px;
    color: rgb(255, 255, 255);
    border-radius: 2px;
    font-size: 26px;
  }

  & .login_input {
    padding-left: 3px;
    width: 250px;
    height: 30px;
    border-radius: 5px;
    border-style: none;
    margin-top: 30px;
  }

  & .pass_input {
    padding-left: 3px;
    width: 250px;
    height: 30px;
    border-style: none;
    border-radius: 5px;
    margin-top: 30px;
  }

  & .buttons {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    & .input_btn {
      cursor: pointer;
      width: 250px;
      height: 30px;
      border-radius: 5px;
    }
  }

  & .alert {
    position: absolute;
    bottom: 105%;
    width: 100%;

    & div {
      display: flex;
      height: 70px;
      align-items: center;
    }
  }
}
