.layout {
  background-image: url("../../img/background.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
  z-index: -3;
}

.container {
  max-width: 100%;
  margin: 0 auto;
  padding: 0 15px;
  height: 100vh;
  overflow: hidden;
}

.myVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}
