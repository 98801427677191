.ql-editor {
    min-height: 200px !important;
    max-height: 600px;
}
.ql-editor[data-top="true"] ~ .ql-tooltip.ql-editing.ql-flip {
    top: 23px;
}

.ql-container {
    overflow: auto;
}
.text-editor {
    width: 100%;
    margin: 0 auto;
}

.ql-tooltip.ql-editing.ql-flip {
    /* transform: translateX(50%) !important; */
    left: 15px !important;
    z-index: 100000000;
}
