.wrapper {
    width: 100%;
    height: 70vh;
    border-radius: 5px;
    background-color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    justify-content: center;

    & .root {
        width: 100%;
        height: 100%;
        padding: 20px 0 0 0;
    }

    & .title {
        position: absolute;
        font-size: 16px;
        transform: translateX(-50%);
        left: 50%;
        color: black;
        width: auto;
        white-space: nowrap;
    }

    & .radar {
        position: relative;
        width: 100%;
        height: 100%;
        & div {
            transform: translate(-50%, -50%);
            left: 50%;
            top: 50%;
        }
        & svg {
            height: 650px !important;
            width: 650px !important;
        }
    }

    & .clarification {
        width: 100%;
        height: 100%;

        display: flex;
        flex-direction: column;
        padding-top: 60px;

        & span {
            position: absolute;
            top: 20px;
            font-weight: 500;
            font-size: 18px;
        }

        & .tableWrapper {
            // position: absolute;
            // top: 0;
            // left: 0;
            width: 100%;
            flex: 1;
            overflow-y: scroll;
            border-radius: 5px;
            padding: 10px 5px 10px 0;
            display: flex;
            flex-direction: column;
            padding: 10px;
            gap: 15px;
            box-shadow: inset 0 5px 10px rgba($color: #000000, $alpha: 0.1);

            & .title {
                width: 100%;
                text-align: center;
                height: auto;
            }

            & .number {
                width: 10%;
            }

            & .name {
                width: 90%;
                border-left: solid 1px rgba(0, 0, 0, 0.3);
            }

            & .row {
                display: flex;
                padding: 5px;
                padding: 10px 0;
                background-color: rgba(52, 119, 196, 0.801);
                border-radius: 10px;
                color: rgb(255, 255, 255);

                &:first-child {
                    background-color: rgb(0, 75, 110);
                    color: white;
                    border-radius: 5px;
                    margin-bottom: 10px;

                    & div {
                        font-weight: 500;
                        font-size: 20px;
                    }
                }

                & div {
                    display: flex;
                    font-size: 18px;
                    justify-content: center;
                    padding: 5px 20px;
                }

                & .name {
                    justify-content: flex-start;
                }

                & .header {
                    justify-content: center;
                }

                &:hover {
                    cursor: pointer;
                }
            }

            & .parity {
                background-color: rgba(226, 26, 11, 0.747);
            }
        }
    }
}
