@import "../style.modules.scss";

.wrapper {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .avatars_contaier {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 50px;
        width: 80%;
        height: 90%;
        align-items: center;
        justify-content: center;

        & .avatar_container {
            width: 20%;
            min-width: 230px;
            display: flex;
            flex-direction: column;
            align-items: center;

            &__selected {
                & span {
                    color: #006eff;
                }
            }
            &:hover {
                cursor: pointer;
            }

            & video {
                width: 100%;
            }

            & img {
                width: 100%;
            }

            & span {
                font-size: 18px;
                font-weight: 500;
            }
        }
    }
}

@media (max-width: 1400px) {
    .wrapper {
        & .avatars_contaier {
            width: 100%;
            & .avatar_container {
                width: 25%;
            }
        }
    }
}

@media (max-height: 800px) {
    .wrapper {
        & .avatars_contaier {
            width: 100%;
            & .avatar_container {
                width: 24%;
                min-width: 100px;
            }
        }
    }
}
