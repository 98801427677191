.container {
    width: 100%;
    position: relative;

    & .info_icon {
        position: absolute;
        right: 2px;
        top: 2px;
        color: rgb(0, 153, 255);
        width: 20px;
        height: 20px;

        &:hover {
            cursor: pointer;
        }
    }

    & .info_block {
        position: absolute;
        display: none;
        opacity: 0;
        z-index: 100000;

        // height: 0;
        // width: 0;
        transition: opacity 0.5s;
        transform: translateX(100%);
        right: -10px;
        border-radius: 5px;
        background-color: white;
        box-shadow: 0px 0px 10px 7px rgba(0, 0, 0, 0.1);
        height: auto;
        width: auto;

        &__visable {
            overflow: hidden;
            display: block;
            opacity: 1;
        }
    }

    & textarea {
        padding-right: 15px !important;
    }
}
