.main {
    display: flex;
    position: fixed;
    top: 0;
    left: -503px;
    // left: -103px;
    z-index: 100000;
    height: 100%;

    & .course_menu {
        overflow-y: auto;
        overflow-x: hidden;
        width: 500px;
        background-color: rgba(1, 56, 70, 0.897);
        padding-right: 20px;
        box-shadow: 3px 0 3px rgba(255, 255, 255, 0.616);

        & .year {
            margin-top: 50px;
            width: 500px;
            display: flex;
            flex-direction: column;
            align-items: center;

            & .title {
                color: white;
                font-size: 24px;
            }

            & select {
                margin-top: 20px;
                padding: 2px 20px;
                border-radius: 2px;
                font-weight: 500;
            }
        }

        & .list {
            margin-top: 50px;
            width: 500px;
            height: auto;

            & .title {
                color: white;
                text-align: center;
            }

            & .block {
                margin-top: 20px;
                color: rgb(177, 177, 177);

                & h2 {
                    padding: 5px 20px;
                }
            }

            & .item {
                display: flex;
                align-items: center;
            }
        }

        &::-webkit-scrollbar {
            width: 0;
            background-color: #ffd00091;
        }
    }
}

.marker {
    height: 50px;
    width: 50px;
    margin-top: 10px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: rgb(1, 56, 70);
    box-shadow: 3px 3px 3px rgba(255, 255, 255, 0.616);
    position: relative;
    z-index: +1;
    display: flex;
    justify-content: center;
    align-items: center;

    & .fix {
        height: 35px;
        width: 35px;
        cursor: pointer;
        background-color: transparent;
        border-radius: 50%;
        border: 2px solid rgb(104, 156, 252);
        text-align: center;
        color: rgb(104, 156, 252);
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.main.active {
    transform: translateX(+503px);
    transition: all 0.2s ease-in;
}
