.main {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;

    & .input_block {
        display: flex;
        flex-direction: column;
        gap: 10px;

        & .button {
            width: 250px;
            font-size: 14px;
            height: 40px;
        }
        & .buttons {
            display: flex;
            flex-direction: row;
            gap: 15px;
        }
    }

    & p {
        font-size: 24px;
        font-weight: 500;
    }

    overflow-y: scroll;
}
