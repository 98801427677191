.data_container {
    // width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    max-height: 550px;
    overflow-y: auto;
    padding-right: 5px;
}

.image_container {
    display: flex;
    gap: 20px;
    flex-direction: column;

    & img {
        width: 100%;
    }
}

.video_container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.youtube {
    width: 100%;

    & iframe {
        width: 100%;
        height: 300px;
    }
}
