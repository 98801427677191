.container {
    width: 100%;
    // height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    & svg {
        transition: color 1s;
    }

    & .top {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 10px;
        position: relative;

        & .video {
            min-height: 450px;
            width: calc(100% - 40px * 2);
            height: 100%;
            position: relative;

            & .preloader {
                background-color: rgba($color: #ffffff, $alpha: 0.4);
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100% !important;

                & .loader {
                    height: 100%;
                }
            }

            & iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }

        & .button_next {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            width: 40px;
            height: 40px;

            & svg {
                width: 100%;
                height: 100%;
                color: #006eff;
            }

            &_loading {
                & svg {
                    color: rgba($color: #006eff, $alpha: 0.2);
                }
            }
        }

        & .button_prev {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            width: 40px;
            height: 40px;

            & svg {
                width: 100%;
                height: 100%;
                color: #006eff;
            }

            &_loading {
                & svg {
                    color: rgba($color: #006eff, $alpha: 0.2);
                }
            }
        }
    }

    .pagination {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 10px;
        z-index: 200;
        margin-top: 10px;
        height: 25px;
    }

    .pagination_item {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: rgba($color: #006eff, $alpha: 0.2);
    }

    .pagination_item_selected {
        width: 25px;
        height: 25px;
        background-color: rgba($color: #006eff, $alpha: 0.2);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        animation: pagination 0.2s cubic-bezier(0.04, 0.64, 0.17, 1.05);

        & div {
            width: 15px;
            height: 15px;
            border-radius: 50%;
            background-color: #006eff;
        }
    }

    @keyframes pagination {
        0% {
            width: 15px;
            height: 15px;
            margin: 0 5px;
        }
        100% {
            width: 25px;
            height: 25px;
            margin: 0;
        }
    }
}
