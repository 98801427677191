.close {
  padding-bottom: 1px;
  font-size: 18px;
  margin-top: 20px;
  margin-left: auto;
  display: block;
  width: 100px;
  height: 36px;
  background-color: #f00000;
  color: #ffffff;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
}

.close:hover {
  background-color: #b30000;
}

.title {
  font-size: 25px;
  color: #222222;
  line-height: 1;
  margin-bottom: 15px;
}
