@import "../style.module.scss";

.container {
    width: 520px;
    padding: none;

    & .header {
        font-size: 24px;
        font-weight: 500;
    }

    & .edit {
        position: absolute;
        top: 10px;
        right: 50px;

        &:hover {
            cursor: pointer;
        }
    }
}

.form_container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & .buttons_container {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        padding: 20px 20px;

        & .button {
            height: 45px;
            width: 200px;
            background-color: #23a6d5;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}
