.main {
    height: calc(100% - 85px);

    & .header {
        height: 50px;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        overflow-x: auto;
        transform: rotateX(180deg);
        box-shadow: 10px 0px 5px rgba($color: #000000, $alpha: 0.2);

        & div {
            transform: rotateX(180deg);
            flex: 1;
            min-width: 200px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            & span {
                width: 80%;
                text-align: center;
                font-size: 24px;
                &:hover {
                    cursor: pointer;
                }
            }
            &::after {
                border-radius: 1px;
                transition: width 0.2s, background-color 0.2s;
                position: absolute;
                bottom: 0;
                background-color: rgba($color: #23a6d5, $alpha: 0.2);
                content: "";
                width: 60%;
                height: 2px;
            }
            &[data-selected="true"] {
                &::after {
                    background-color: #23a6d5;
                    width: 80%;
                }
            }
        }
    }

    & .tabs {
        overflow-x: hidden;
        display: flex;
        height: calc(100% - 50px);
        & .tab {
            position: relative;
            width: 100%;
            min-width: 100%;
            height: 100%;
        }
    }
}
