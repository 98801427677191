.main {
    padding: 10px;
    font-size: 14px;
    width: 515px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    & b {
        user-select: text;
    }

    & ul {
        margin-left: 20px;
    }
    & div {
        display: inline;
        & a {
            display: inline;
        }
    }
}
