.header {
    background-color: white;
    width: 100%;
    height: 70px;
    border-bottom: solid 1px rgba($color: #000000, $alpha: 0.2);
    box-shadow: 0 0 5px rgba($color: #000000, $alpha: 0.2);

    padding: 0 40px !important;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    & .logos {
        height: 80%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 10px;

        & img {
            height: 100%;
        }
    }

    & .school {
        & p {
            font-size: 20px;
        }
    }

    & .user {
        height: 80%;
        display: flex;
        flex-direction: row;
        gap: 10px;

        & .info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: end;

            & p {
                font-weight: 500;
            }
        }

        & .avatar {
            height: 55px;
            width: 55px;
            border-radius: 50%;
            overflow: hidden;

            & img {
                width: 100%;
                object-fit: fill;
            }
        }
    }
}
