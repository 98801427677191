@import "../style.modules.scss";

.main {
    border-radius: 3px;
    overflow: hidden;

    .button {
        position: absolute;
        top: 0;
        transform: translateY(-50%);
        left: 130px;
    }
}

.wrapper {
    padding: 0;

    & .theme_selector_container {
        width: 100%;
        height: 100%;
        // position: relative;
    }
}
