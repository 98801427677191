.main {
    display: flex;
    flex-direction: column;
    gap: 10px;

    & .text {
        font-size: 16px;

        & .point {
            margin-left: 10px;
            display: inline-block;
            width: 15px;
            height: 15px;
            border-radius: 50%;

            &__green {
                background-color: #22c55e;
            }

            &__red {
                background-color: #ec486b;
            }
        }
    }
}
