.radar {
    position: relative;
    width: 100%;
    height: 100%;

    & div {
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
    }

    .empty {
        position: absolute;

        & p {
            font-size: 24px;
            color: black;
        }
    }

    & svg {
        height: 650px !important;
        width: 650px !important;
        
        @media (max-height: 800px) {
            height: 550px !important;
            width: 550px !important;
        }
    }

    & .title {
        position: absolute;
        font-size: 16px;
        transform: translateX(-50%);
        left: 50%;
        // top: -35px;
        width: 100%;
        color: black;
        width: auto;
        white-space: nowrap;
    }
}

.root {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    overflow: visible;
    // padding: 0 20px;
    padding: 20px 0 0 0;

    & .header {
        display: flex;
        flex-direction: row;
        position: relative;
        width: 100%;
        overflow: visible;
        align-items: center;
        justify-content: center;

        & .back_button {
            position: absolute;
            left: 120px;
            top: -35px;
        }

        & .title {
            font-size: 18px;
            color: black;
            width: 70%;
            text-align: center;
            font-weight: 500;
        }
    }

    & .tableWrapper {
        width: 100%;
        flex: 1;
        overflow-y: scroll;
        border-radius: 5px;
        padding: 10px 5px 10px 0;
        display: flex;
        flex-direction: column;
        padding: 10px;
        gap: 15px;
        box-shadow: inset 0 5px 10px rgba($color: #000000, $alpha: 0.1);

        & .star {
            color: rgba($color: #ffd700, $alpha: 1);
            fill: rgba($color: #ffd700, $alpha: 1);
        }

        & .themeDate {
            width: 15%;
        }

        & .themeName {
            width: 35%;
            border-left: solid 1px rgba(0, 0, 0, 0.3);
        }

        & .themeGrade {
            width: 15%;
            border-left: solid 1px rgba(0, 0, 0, 0.3);
        }

        & .themeHomework {
            width: 25%;
            border-left: solid 1px rgba(0, 0, 0, 0.3);
        }

        & .kpaz {
            width: 10%;
            border-left: solid 1px rgba(0, 0, 0, 0.3);
        }

        & .oez {
            width: 10%;
            border-left: solid 1px rgba(0, 0, 0, 0.3);
        }

        & .rating {
            width: 10%;
            border-left: solid 1px rgba(0, 0, 0, 0.3);

            & div {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                gap: 5px;
            }
        }

        & .row {
            display: flex;
            padding: 5px;
            padding: 10px 0;
            background-color: rgba(52, 119, 196, 0.801);
            border-radius: 10px;
            color: rgb(255, 255, 255);

            &:first-child {
                background-color: rgb(0, 75, 110);
                color: white;
                border-radius: 5px;
                margin-bottom: 10px;

                & div {
                    font-weight: 500;
                    font-size: 20px;
                }
            }

            & div {
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                font-size: 14px;
            }

            &:hover {
                cursor: pointer;
            }
        }

        & .parity {
            background-color: rgba(226, 26, 11, 0.747);
        }
    }

    & .alert .text {
        font-size: 18px;
        font-weight: 500;
        color: brown;
    }
}

.clarification {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    padding-top: 60px;

    & span {
        position: absolute;
        top: 20px;
        font-weight: 500;
        font-size: 18px;
    }

    & .title {
        width: 100%;
        text-align: center;
        height: auto;
    }

    & .tableWrapper {
        width: 100%;
        flex: 1;
        overflow-y: scroll;
        border-radius: 5px;
        padding: 10px 5px 10px 0;
        display: flex;
        flex-direction: column;
        padding: 10px;
        gap: 15px;
        box-shadow: inset 0 5px 10px rgba($color: #000000, $alpha: 0.1);

        & .number {
            width: 10%;
        }

        & .name {
            width: 90%;
            border-left: solid 1px rgba(0, 0, 0, 0.3);
        }

        & .row {
            display: flex;
            padding: 5px;
            padding: 10px 0;
            background-color: rgba(52, 119, 196, 0.801);
            border-radius: 10px;
            color: rgb(255, 255, 255);

            &:first-child {
                background-color: rgb(0, 75, 110);
                color: white;
                border-radius: 5px;
                margin-bottom: 10px;

                & div {
                    font-weight: 500;
                    font-size: 20px;
                }
            }

            & div {
                display: flex;
                font-size: 18px;
                justify-content: center;
                padding: 5px 20px;
            }

            & .name {
                justify-content: flex-start;
            }

            & .header {
                justify-content: center;
            }

            &:hover {
                cursor: pointer;
            }
        }

        & .parity {
            background-color: rgba(226, 26, 11, 0.747);
        }
    }
}
