.fingerBlock {
    background-color: rgba(255, 255, 255, 0.884);
    padding: 2rem;
    background: linear-gradient(
        0deg,
        rgba(42, 44, 133, 1) 0%,
        rgba(26, 84, 178, 1) 0%,
        rgba(0, 151, 255, 1) 100%,
        rgba(71, 117, 139, 1) 100%
    );
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    & .back {
        position: absolute;
        top: 10px;
        left: 10px;
        font-size: 20px;
        font-weight: 500;
        color: white;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    & h1 {
        margin: 30px 100px;
        color: white;
    }

    & .svg {
        color: #b9b6b6;
    }
    
    & .greenColor {
        color: #22c55e;
        transition: all 1s ease-in;
    }
    
    & .redColor {
        color: #e21014;
        transition: all .3s ease-in;
    }


}