.main {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;

    & .header {
        font-size: 14px;
        font-weight: 500;
    }
}
