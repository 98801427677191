.wrapper {
    width: 100%;
    height: 70vh;
    border-radius: 5px;
    background-color: rgb(255, 255, 255);
    // display: flex;
    // align-items: center;
    // justify-content: center;
    padding: 1rem;

    & .root {
        width: 100%;
        height: 100%;
        padding: 20px 0 0 0;
    }

    & .title {
        position: absolute;
        font-size: 16px;
        transform: translateX(-50%);
        left: 50%;
        color: black;
        width: auto;
        white-space: nowrap;
    }

    & .radar {
        position: relative;
        width: 100%;
        height: 100%;
        & div {
            transform: translate(-50%, -50%);
            left: 50%;
            top: 50%;
        }
        & svg {
            height: 650px !important;
            width: 650px !important;
        }
    }

    & .clarification {
        width: 100%;
        height: 100%;

        display: flex;
        flex-direction: column;
        padding-top: 60px;

        & span {
            position: absolute;
            top: 20px;
            font-weight: 500;
            font-size: 18px;
        }

        & .tableWrapper {
            // position: absolute;
            // top: 0;
            // left: 0;
            width: 100%;
            flex: 1;
            overflow-y: scroll;
            border-radius: 5px;
            padding: 10px 5px 10px 0;
            display: flex;
            flex-direction: column;
            padding: 10px;
            gap: 15px;
            box-shadow: inset 0 5px 10px rgba($color: #000000, $alpha: 0.1);

            & .title {
                width: 100%;
                text-align: center;
                height: auto;
            }

            & .number {
                width: 10%;
            }

            & .name {
                width: 90%;
                border-left: solid 1px rgba(0, 0, 0, 0.3);
            }

            & .row {
                display: flex;
                padding: 5px;
                padding: 10px 0;
                background-color: rgba(52, 119, 196, 0.801);
                border-radius: 10px;
                color: rgb(255, 255, 255);

                &:first-child {
                    background-color: rgb(0, 75, 110);
                    color: white;
                    border-radius: 5px;
                    margin-bottom: 10px;

                    & div {
                        font-weight: 500;
                        font-size: 20px;
                    }
                }

                & div {
                    display: flex;
                    font-size: 18px;
                    justify-content: center;
                    padding: 5px 20px;
                }

                & .name {
                    justify-content: flex-start;
                }

                & .header {
                    justify-content: center;
                }

                &:hover {
                    cursor: pointer;
                }
            }

            & .parity {
                background-color: rgba(226, 26, 11, 0.747);
            }
        }
    }
}

.main {
    width: 100%;

    & .message {
        text-align: center;
        font-size: 26px;
        font-weight: 700;
        width: 100%;
        color: cadetblue;
        margin: 2rem 0 3rem;
    }

    & .link {
        color: rgba(51, 135, 247, 0.9);
    }

    & .textarea {
        width: 100%;
        border: 2px solid rgba(51, 135, 247, 0.9);
        font-size: 17px;
        padding: 1rem;
        margin: 1rem 0;
        resize: none;
        height: 300px;
    }
}


.micro {
    div[role="button"] {
        border: 0.0625rem solid;
        width: 68px;
        font-size: 0;
      }
}

.svgrec {
    color: red;
    width: 4rem;
    height: 4rem;
    font-size: 4rem;
    display: block;
    margin-right: 1rem;
}

.recDiv {
    margin: 1rem;
    text-align: center;
    display: flex;
    justify-content: center
}


@mixin rings($duration, $delay) {
    opacity: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -8px;
    left: -8px;
    right: 0;
    bottom: 0;
    content: '';
    height: 100%;
    width: 100%;
    border: 8px solid rgba(247, 36, 36, 0.2);
    border-radius: 100%;
    animation-name: ripple;
    animation-duration: $duration;
    animation-delay: $delay;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(.65,0,.34,1);
    z-index: -1;
}

.requestLoader {
//   cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 75px;
  width: 75px;
  border-radius: 100%;
  background-color: red;
//   box-shadow: 0 0 20px 0 rgba(0,0,0,0.25);
  
  span {
    position: relative;
    font-size: 72px;
    top: 5px;
    left: -5px;
  }
  
  &::after {
    @include rings(2s, 0s);
    z-index: 2;
    left: 0;
    top: 0;
  }
  
  &::before {
    @include rings(2s, 0.5s);
    z-index: 2;
    left: 0;
    top: 0;  
}
}


@keyframes ripple {
  from {
    opacity: 1;
    transform: scale3d(0.75,0.75,1);
  }
  
  to {
    opacity: 0;
    transform: scale3d(1.5,1.5,1);
  }
}

.textRec {
    font-size: 25px;
    color:  #000;
    margin-left: 1rem;
}

.wrapperRec {
    display: flex;
    align-items: center;
}

.recBut {
    display: block;
    width: 100%;
}

.mic {
    cursor: pointer;
}