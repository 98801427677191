.main_container {
    width: 30vw;
    min-width: 300px;
    max-width: 350px;
    height: 100%;
    position: relative;
    // background-color: white;
}

.main {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    background-color: white;
    box-shadow: -2px 5px 10px 2px rgba($color: #000000, $alpha: 0.1);
    justify-content: space-between;
    overflow-y: auto;
    padding: 40px 0;
}

.loader_background {
    position: absolute;
    top: 0;
    right: 0;
    // flex: 1;
    // width: 311.5px;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    // width: 300px;
    width: 100%;
    height: 100%;
}
.loader_background div {
    margin: 0;
}

.description {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.nodes {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}

.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    margin: 20px 0;
}

.button {
    background: linear-gradient(-50deg, #3b82f6, #22c55e);
    border-style: none;
    padding: 12px;
    font-size: 14px;
    height: 50px;
    color: white;
    border-radius: 5px;
    background-size: 200% 200%;
    width: 250px;
    animation: gradient 10s ease infinite;
    box-shadow: 0px 0px 3px 3px rgba($color: #3b82f6, $alpha: 0.3);
    cursor: pointer;
    font-size: 20px;
    letter-spacing: 1px;

    &:hover {
        box-shadow: 0px 0px 1px 3px rgba($color: #3b82f6, $alpha: 0.3);
    }

    &:active {
        box-shadow: 0px 0px 1px 3px rgba($color: #3b82f6, $alpha: 0.6);
    }
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
