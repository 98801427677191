.root {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
}

.block {
    position: relative;
    min-height: 20px;
}
.top_line {
    position: absolute;
    top: 0;
    height: 1px;
    width: 0;
    background-color: rgba(0, 0, 0, 0.25);
    transition: width 0.25s ease-in-out;
    transform: translateX(20px);
}
.top_line_open {
    width: calc(100% - 40px);
}
.bottom_line {
    position: absolute;
    bottom: 0;
    height: 1px;
    width: 0;
    background-color: rgba(0, 0, 0, 0.25);
    transition: width 0.25s ease-in-out;
    transform: translateX(-50%);
    left: 50%;
}
.bottom_line_open {
    width: calc(100% - 40px);
}

.block_header {
    background: linear-gradient(-50deg, #3b82f6, #22c55e);
    color: white;
    background-size: 200% 200%;
    animation: gradient 10s ease infinite;
    cursor: pointer;
    position: absolute;
    transform: translateY(-50%);
    padding: 5px 10px;
    left: 15px;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}
.block_header:hover {
    box-shadow: 0px 0px 1px 3px rgba(59, 130, 246, 0.3);
}
.block_header:active {
    box-shadow: 0px 0px 1px 3px rgba(59, 130, 246, 0.6);
}
.block_header p {
    padding: 0;
    margin: 0;
    font-size: 18px;
    line-height: 18px;
}

.block_content {
    max-height: 100000px;
    transition: max-height 1s ease-in-out, margin 1s cubic-bezier(0, 1, 0, 1);
    /* overflow: hidden; */
}
.block_content[aria-expanded="true"] {
    max-height: 0px;
    transition: max-height 0.5s cubic-bezier(0, 1, 0, 1), margin 0.5s ease-in-out;
    margin: 0 30px;
    height: 0;
    overflow: hidden;
    /* display: none; */
}
.block_content[aria-expanded="false"] {
    margin: 30px 30px 20px 30px;
}
