.audios_container {
    display: flex;
    justify-content: center;
    padding: 20px 0;

    & .audios {
        $container-width: 95%;
        $audio-width: 350px;
        $margin: calc(($container-width - 3 * $audio-width) / 3);
        width: $container-width;
        margin-left: $margin;

        & div {
            width: $audio-width;
        }
    }
}
