.wrapper {
    background-color: #f0f0f0;
    position: relative;
    width: 60vw;
    /* height: 69vh; */
    height: 69vh;
    overflow-y: auto;
    /* padding: 15px; */
    border-radius: 3px;
    color: rgb(37, 37, 37);
    font-size: 14px;
    border-radius: 5px;
    padding: 20px 0;
}