@import "../style.module.scss";

.data_container {
    & .answer {
        padding: 20px;
        border-radius: 20px;
        background-color: rgba(51, 135, 247, 0.25);
        position: relative;

        & .text {
            width: 90%;
        }

        & .icon {
            position: absolute;
            right: 20px;
        }
    }
}
