.container {
    // background-color: red;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    & .header {
        padding: 20px 10px 10px 10px;
        height: 90px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        & p {
            font-size: 24px;
            font-weight: 500;
        }
    }

    & .videos_container {
        padding: 15px;
        overflow-y: auto;
        width: 100%;
        // height: 100%;
        height: calc(100% - 90px);
        // display: flex;
        // justify-content: center;

        box-shadow: inset 0 5px 10px rgba($color: #000000, $alpha: 0.1);

        & .not_empty {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            // padding: 5px 20px 20px 20px;
            // gap: 15px;
            gap: calc((100% - 24% * 4) / 3);
            @media (max-width: 1500px) {
                gap: calc((100% - 32% * 3) / 2);
            }
        }

        & .empty {
            height: 70px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 18px;
            font-weight: 500px;
        }

        &::-webkit-scrollbar {
            /* display: none; */
            width: 12px;
            height: 3px;
        }
    }
}
