.hidden {
  font-size: 25px;
  background-color: rgb(240, 240, 240);
  border: 1px solid #000000;
  width: 600px;
  height: 100px;
  position: absolute;
  top: -200px;
  left: 50%;
  border-radius: 10px;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: .5s;
}

.visible {
  font-size: 25px;
  background-color: rgb(240, 240, 240);
  border: 1px solid #000000;
  width: 600px;
  height: 100px;
  position: absolute;
  top: 20px;
  left: 50%;
  border-radius: 10px;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: .5s;
}

.title {
  text-align: center;
  margin-top: 5px;
}

.button {
  margin-left: auto;
  margin-right: 10px;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  background-color: #1d65ff;
  color: #ffffff;
  padding: 5px;
  border-radius: 5px;
  transition: 0.2s;
}

.button:hover {
  background-color: #0047e0;
}
