.questionBlock {
    justify-content: space-between;
    background-color: rgb(51, 135, 247);
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
    align-self: center;
    width: 80%;
    padding: 5px;
    border-radius: 5px;
    margin-top: 10px;
    min-height: 50px;
}
.questionBlock .question_block_content {
    display: flex;
    height: 100%;
    flex-direction: row;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
    padding: 0 5px 0 0;
}
.questionBlock .question_block_content .question_block_content_message {
    background-color: rgb(188, 217, 255);
    flex: 1;
    padding: 0;
    margin: 0;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: flex-start;
    align-items: center;
    border-radius: 5px;
    overflow: hidden;
}
.questionBlock .question_block_content .question_block_content_message p {
    color: rgb(1, 62, 143);
    margin: 0 5px;
    text-align: start;
}
.questionBlock .question_block_content .question_block_content_message textarea {
    color: rgb(1, 62, 143);
    height: 100%;
    width: 100%;
    border-width: 0;
    font-size: 15px;
    resize: none;
    /* margin: 0 5px; */
    text-align: start;
}

.questionBlock .question_block_content .icon {
    width: 25px;
    height: 25px;
    transition: transform 0.3s, color 0.3s;
    cursor: pointer;
}

.icon:hover {
    transform: scale(0.9);
}

.mic_container {
    background-color: rgb(0, 110, 255);
    transition: background-color 1s;
    padding: 5px;
    border-radius: 50%;
}
.mic_container_active {
    background-color: rgb(255, 57, 90);
    animation: rec 1.5s ease infinite;
}

@keyframes rec {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}
