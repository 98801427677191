.wrapper {
    text-align: center;
    overflow-y: auto;
    background-color: rgb(0, 110, 255);
    width: 480px;
    height: 280px;
    border-radius: 5px;
    padding: 10px;
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    overflow: visible;
}

@media (max-height: 800px) {
    .wrapper {
        width: 400px;
        height: 250px;
    }
}

@media (max-width: 1280px) {
    .wrapper {
        font-size: 16px;
    }
}

.dialog_wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    overflow-y: auto;
}

.dialog_wrapper .expected_responses {
    width: 80%;
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
}
.dialog_wrapper .expected_responses .header {
    text-align: start;
    font-size: 12px;
    font-weight: normal;
}
.dialog_wrapper .expected_responses .list {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 5px;
    overflow-y: scroll;
    overflow-y: hidden;
    padding: 3px 3px;
}

.dialog_wrapper .expected_responses .list_item {
    white-space: nowrap;
    background-color: rgb(51, 135, 247);
    padding: 5px 10px;
    border-radius: 2px;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
}
.dialog_wrapper .expected_responses .list_item p {
    font-size: 12px;
    font-weight: normal;
}

.availableCommands {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-self: center;
    gap: 5px;
}

.availableCommands p {
    background-color: red;
    width: 80%;
}

/* .aleshaMessage {
    flex-shrink: 0;
    line-height: 1.2;
    border-radius: 5px;
    max-width: 450px;
    padding: 10px;
    background-color: brown;
    margin-bottom: 10px;
    margin-right: auto;
  }
  
  .studentMessage {
    flex-shrink: 0;
    line-height: 1.2;
    border-radius: 5px;
    text-align: right;
    margin-left: auto;
    max-width: 450px;
    padding: 10px;
    background-color: darkblue;
    margin-bottom: 10px;
  } */
