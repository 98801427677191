.answer_input {
    width: 100%;
    height: 100px;
    background-color: white;
    border-color: #05386b;
    border-style: solid;
    border-radius: 5px;
    border-width: 1px;
    box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
}

.answer_input textarea {
    flex: 1;
    height: 100%;
}

.answer_input .icon {
    width: 50px;
    transition: transform 0.3s, color 0.3s;
}

.icon:hover {
    transform: scale(0.9);
}

.buttons {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.rec {
    animation: rec 1.5s ease infinite;
}

@keyframes rec {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}

.answers {
    display: flex;
    flex-direction: column;
    gap: 10px
}

.answer {
    background-color: white;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: start;
    height: 30px;
    padding: 10px;
    color: black;
    border-radius: 5px;
}

.button {
    width: 100%;
}