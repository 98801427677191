.main {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    & .message {
        text-align: center;
        font-size: 26px;
        font-weight: 700;
        width: 80%;
        color: cadetblue;
    }
}
