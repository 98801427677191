.loader {
    --color: #fff;
    --size: 64px;
    --border-width: 8px;
    display: inline-block;
    position: relative;
    width: 100%;
    height: 100%;
    & div {
        transition: border-color 0.5s;
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: var(--size);
        height: var(--size);
        margin: 8px;
        border: var(--border-width) solid var(--color);
        top: 40%;
        left: 45%;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: var(--color) transparent transparent transparent;
    }
    & div:nth-child(1) {
        animation-delay: -0.45s;
    }
    & div:nth-child(2) {
        animation-delay: -0.3s;
    }
    & div:nth-child(3) {
        animation-delay: -0.15s;
    }
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
